import { Controller } from "@hotwired/stimulus";
import Swiper, { SwiperOptions, Navigation, Autoplay, Mousewheel, Scrollbar } from "swiper";

export default class extends Controller {
  static values = { options: Object };
  declare optionsValue: SwiperOptions;
  declare swiper: Swiper | undefined;

  connect(): void {
    this.swiper = new Swiper(this.element as HTMLElement, {
      ...this.defaultOptions,
      ...this.optionsValue,
    });
  }

  disconnect(): void {
    this.swiper?.destroy();
    this.swiper = undefined;
  }

  get defaultOptions(): SwiperOptions {
    return {
      cssMode: true,
      mousewheel: true,
      passiveListeners: false,
      observer: true,
      observeParents: true,
      touchReleaseOnEdges: true,
      modules: [Navigation, Autoplay, Mousewheel, Scrollbar],
    };
  }
}
