import { Controller } from "@hotwired/stimulus";
declare function ytag(arg: unknown): void;

const TRACKING_MAPPING: { [key: string]: { google: string; yahoo: { yahoo_conversion_id: string; yahoo_conversion_label: string } } } = {
  listing: {
    google: "AW-435076884/fL-ZCJ3GzvMBEJT-us8B",
    yahoo: { yahoo_conversion_id: "1001180067", yahoo_conversion_label: "9713CP6HiPQBEKzEvc8B" },
  },
};

export default class extends Controller {
  static values = { category: String, action: String, label: String, value: Number, track: String };
  declare categoryValue: string;
  declare actionValue: string;
  declare labelValue: string;
  declare valueValue: number;
  declare trackValue: string;
  declare hasTrackValue: boolean;

  track(): void {
    if (this.hasTrackValue) {
      gtag("event", "conversion", { send_to: TRACKING_MAPPING[this.trackValue]["google"] });
      ytag({ type: "yss_conversion", config: TRACKING_MAPPING[this.trackValue]["yahoo"] });
    }
    gtag("event", this.actionValue, { event_category: this.categoryValue, event_label: this.labelValue, value: this.valueValue });
  }
}
