import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { height: Number };
  static classes = ["height", "hidden"];
  static targets = ["container"];

  declare containerTarget: HTMLElement;
  declare heightClass: string;
  declare hiddenClass: string;
  declare heightValue: number;

  connect(): void {
    if (this.element.clientHeight > this.heightValue) {
      this.element.classList.add(this.heightClass);
      this.containerTarget.classList.remove(this.hiddenClass);
    }
  }

  show(): void {
    this.element.classList.remove(this.heightClass);
    this.containerTarget.remove();
  }
}
