import { Application } from "@hotwired/stimulus";

import ReadmoreController from "./readmore-controller";
import LinkTrackingController from "./link-tracking-controller";
import NoimageController from "./noimage-controller";
import SwiperController from "./swiper-controller";

const application = Application.start();
application.register("readmore", ReadmoreController);
application.register("link-track", LinkTrackingController);
application.register("noimage", NoimageController);
application.register("swiper", SwiperController);
