import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["template", "image"];

  declare imageTarget: HTMLImageElement;
  declare templateTarget: HTMLTemplateElement;

  connect(): void {
    if (this.imageTarget.complete && this.imageTarget.naturalWidth < 2 && this.imageTarget.naturalHeight < 2) {
      this.replace();
    }
  }

  loaded(): void {
    if (this.imageTarget.complete && this.imageTarget.naturalWidth < 2 && this.imageTarget.naturalHeight < 2) {
      this.replace();
    }
  }

  replace(): void {
    const content = this.templateTarget.innerHTML;
    this.element.outerHTML = content;
  }
}
